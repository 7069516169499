<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      :disable-delete="true"
      :disable-filter="(isLoading || isFetching) && tableData.length === 0"
      @per-page-change="perPage = $event"
    >
      <template #dropdown-items>
        <b-dropdown-item
          :disabled="selectedDatas.length === 0"
          @click="acceptOrder(null)"
        >
          <Feather-icon icon="CheckIcon" class="text-success mr-1" />
          Terima Pesanan ({{ selectedDatas.length }})
        </b-dropdown-item>
        <!-- <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item> -->
      </template>
    </TableHeader>

    <b-skeleton-table
      v-if="isLoading"
      :rows="4"
      :columns="7"
      :table-props="{ borderless: true, striped: true, small: true }"
    />

    <b-table-simple
      v-else
      id="tblData"
      responsive
      striped
      small
      style="zoom: 83%"
    >
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 6%">
            <b-form-checkbox
              v-model="selectAll"
              size="md"
              @change="selectAllRows"
            />
          </b-th>
          <b-th
            v-for="(column, index) in tableColumns"
            :key="index"
            :class="[column.class]"
            :style="{ width: column.style }"
          >
            {{ column.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <template v-for="(data, index) in tableData">
          <b-tr
            :key="'main-row-' + index"
            :style="
              data.productList.length < 1
                ? 'opacity: 0.5; pointer-events: none; user-select: none; border-left: 2px; border-right: 2px; border-top: 2px dashed red; background-color: #f8d7da; cursor: not-allowed;'
                : ''
            "
          >
            <b-td>
              <b-form-checkbox
                v-model="selectedDatas"
                :value="data"
                size="md"
              />
            </b-td>
            <b-td>
              <b-img
                :src="
                  require('@/assets/images/marketplace/' +
                    data.marketplaceType +
                    '.png')
                "
                :alt="data.marketplaceName"
                style="width: 24px; height: 24px"
              />
              {{ data.marketplaceName ? data.marketplaceName : 'Toko Dummy' }}
            </b-td>
            <b-td>
              <div class="d-flex flex-column">
                <div class="my-1">
                  <b-badge variant="primary" class="mr-1">
                    {{
                      marketplaceAbbreviation(data.marketplaceType) +
                      '-' +
                      (data.orderId ? data.orderId : '-')
                    }}
                  </b-badge>
                  <b-badge class="mr-1">
                    {{ formatDateWithTime(data.createdDate) }}
                  </b-badge>
                </div>
                <div>
                  <div class="d-flex flex-column">
                    <b class="mb-1"> Daftar Produk Dibeli :</b>
                    <b-table-simple borderless>
                      <b-tbody>
                        <b-tr
                          v-for="(product, productIndex) in data.productList"
                          :key="productIndex"
                        >
                          <b-td>
                            <div class="d-flex flex-column">
                              <div class="d-flex align-items-center">
                                <b-img
                                  width="60"
                                  :src="
                                    product.image
                                      ? product.image
                                      : 'https://cdn.statically.io/avatar/' +
                                        getFirstCharOfWords(product.productName)
                                  "
                                  :alt="'product #' + productIndex"
                                  thumbnail
                                  class="mr-1"
                                />
                                <div class="flex-grow-1">
                                  <h6 class="text-primary">
                                    <b>{{ product.productName }}</b>
                                  </h6>

                                  <div class="d-flex flex-column mb-1">
                                    <!-- productId -->
                                    <small>
                                      <b> ID Produk :</b>
                                      {{
                                        product.productId
                                          ? product.productId
                                          : '-'
                                      }}
                                    </small>
                                    <!-- SKU -->
                                    <small>
                                      <b>SKU :</b>
                                      {{ product.sku ? product.sku : '-' }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="d-flex flex-column">
                      <span class="line-dashed mt-1" />
                      <small>
                        <dl class="row my-1">
                          <dt class="col-3">Nama Pembeli :</dt>
                          <dd class="col-9">
                            {{ data.recipientName ? data.recipientName : '-' }}
                          </dd>
                          <dt class="col-3">Nomor Resi :</dt>
                          <dd class="col-9">
                            {{
                              data.trackingNumber ? data.trackingNumber : '-'
                            }}
                          </dd>
                          <dt class="col-3">Kurir :</dt>
                          <dd class="col-9">{{ data.courierName }}</dd>
                        </dl>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td class="text-right">
              <strong>{{ formattedCurrency(data.totalPrice) }}</strong>
            </b-td>
            <b-td class="text-center">{{
              formattedNumber(data.quantity)
            }}</b-td>
            <!-- <b-td class="text-center">
              <b-button
                :disabled="
                  data.productList.length < 1 || selectedDatas.length > 0
                "
                variant="primary"
                size="sm"
                block
                class="d-flex justify-content-between align-items-center"
                @click="acceptOrder(data._id)"
              >
                <span>Terima Pesanan</span>
                <font-awesome-icon :icon="['fas', 'check']" />
              </b-button>
              <b-button
                :disabled="data.productList.length < 1"
                variant="danger"
                size="sm"
                block
                class="d-flex justify-content-between align-items-center"
                @click="rejectOrder(data._id)"
              >
                <span>Tolak Pesanan</span>
                <font-awesome-icon :icon="['fas', 'times']" />
              </b-button>
            </b-td> -->
          </b-tr>

          <b-tr
            :key="'productList-' + index"
            :style="
              data.productList.length < 1
                ? 'opacity: 0.5; pointer-events: none; user-select: none; border-left: 2px; border-right: 2px; background-color: #f8d7da; cursor: not-allowed;'
                : ''
            "
          >
            <b-td colspan="3">
              <h5
                :class="
                  timeAgo(data.createdDate) === 'baru saja'
                    ? 'text-warning'
                    : 'text-danger'
                "
              >
                <font-awesome-icon
                  :icon="['fas', 'exclamation-triangle']"
                  class="ml-1"
                />

                {{
                  timeAgo(data.createdDate) === 'baru saja'
                    ? 'pesanan baru saja dibuat'
                    : 'pesanan dibuat ' + timeAgo(data.createdDate)
                }}
              </h5>
            </b-td>
            <b-td colspan="3">
              <div class="d-flex justify-content-end mr-2 my-1">
                <b-button
                  :disabled="
                    data.productList.length < 1 || selectedDatas.length > 0
                  "
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center mr-1"
                  @click="acceptOrder(data)"
                >
                  <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />
                  <span>Terima Pesanan</span>
                </b-button>
                <b-button
                  :disabled="data.productList.length < 1"
                  variant="danger"
                  size="sm"
                  class="d-flex align-items-center"
                  @click="rejectOrder(data)"
                >
                  <font-awesome-icon :icon="['fas', 'times']" class="mr-1" />
                  <span>Tolak Pesanan</span>
                </b-button>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <EmptyTableRow v-else :colspan="tableColumns.length + 1" />
    </b-table-simple>

    <div v-if="isFetching" class="p-2">
      <b-skeleton-table
        :rows="4"
        :columns="7"
        :table-props="{ borderless: true, striped: true, small: true }"
      />
    </div>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from '@axios';
import orderMixins from '../mixins/orderMixins';

export default defineComponent({
  mixins: [orderMixins],
  data() {
    return {
      columns: ['Name', 'ID', 'SKU'],
      tableColumns: [
        {
          label: 'nama toko',
          class: 'bg-transparent text-center',
          style: '15%',
        },
        {
          label: 'Produk dan Data Pesanan',
          class: 'bg-transparent text-center',
          style: '40%',
        },
        {
          label: 'Total Harga',
          class: 'bg-transparent text-center',
          style: '10%',
        },
        {
          label: 'Total Pesanan',
          class: 'bg-transparent text-center',
          style: '15%',
        },
      ],
    };
  },
  methods: {
    acceptOrder(data) {
      // jika tidak ada salah satu pesanan yang dipilih maka akan muncul toast
      if (data === null && this.selectedDatas.length === 0) {
        this.$toast('Pilih pesanan terlebih dahulu');
        return;
      }

      if (this.selectedDatas.length > 0 && data !== null) {
        this.$toast('Pilih satu pesanan saja');
        return;
      }

      this.$swal({
        title: 'Terima Pesanan?',
        text: 'Harap diperhatikan! Pastikan stok barang yang dipesan tersedia.',
        icon: 'question',
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'Ya, Terima',
        cancelButtonText: 'Nanti',
      }).then(async result => {
        if (result.value) {
          // TODO: TEST - Terima Pesanan
          const response = await axios.post(
            `${data.marketplaceType}/order/ack?ns=false`,
            {
              orderId: [data.orderId],
            },
          );
          this.isLoading = true;

          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            this.isLoading = false;
          }
        }
      });
    },
    rejectOrder(data) {
      // jika tidak ada salah satu pesanan yang dipilih maka akan muncul toast
      if (data === null && this.selectedDatas.length === 0) {
        this.$toast('Pilih pesanan terlebih dahulu');
        return;
      }

      if (this.selectedDatas.length > 0 && data !== null) {
        this.$toast('Pilih satu pesanan saja');
        return;
      }

      this.$swal({
        title: 'Tolak Pesanan?',
        text: 'Apakah anda yakin ingin menolak pesanan ini?',
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'Ya, Tolak',
        cancelButtonText: 'Nanti',
      }).then(result => {
        if (result.value) {
          // TODO: API_NOT_READY - Tolak Pesanan
          console.log('Tolak Pesanan', data);
        }
      });
    },
  },
});
</script>

<style lang="scss">
.line-dashed {
  border-bottom: 1px dashed #e0e0e0;
}
</style>
